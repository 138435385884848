import * as Sentry from "@sentry/browser";
import { track } from "./customer-io";

export function logException(exception: Error, handled: boolean): void {
  const sentyEventId = Sentry.captureException(exception);
  track("client-error-occured", {
    was_handled: handled,
    sentry_event_id: sentyEventId,
  });
  console.error("Error captured by Sentry:", exception);
}
